<template>
  <m-widget-container class="m-slideshow">
    <swiper ref="swiper" :options="swiperOptions">
      <swiper-slide
        v-for="(item, index) in items"
        :key="index"
      >
        <m-video
          v-if="item.assetType === 'video'"
          :ref="`swiper-slide-${index}`"
          :src="item.src"
          class="mx-auto"
          preload="auto"
          :autoplay="false"
          :loop="false"
          :muted="item.muted"
          style="width: 100%"
          @ended="next"
        />
        <v-img
          v-else
          :ref="`swiper-slide-${index}`"
          height="100%"
          :src="item.photo | mImage"
        >
          <template #placeholder>
            <div class="d-flex fill-height justify-center align-center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              />
            </div>
          </template>
        </v-img>
      </swiper-slide>
    </swiper>
  </m-widget-container>
</template>

<script>
import { mWidgetMixin } from '../../../mixins'
export default {
  name: 'MSlideshow',
  mixins: [mWidgetMixin],
  props: {
    intervalInSeconds: {
      type: [String, Number],
      default: () => 5
    },
    touchless: {
      type: Boolean,
      required: false
    },
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      swiper: null,
      slideTimeout: null,
      swiperOptions: {
        touchRatio: 1,
        spaceBetween: 0,
        autoplay: false,
        preloadImages: true,
        allowTouchMove: !this.touchless,
        on: {
          init: this.onInit,
          slideChange: this.onSlideChange
        }
      }
    }
  },
  watch: {
    touchless(touchless) {
      this.swiperOptions.allowTouchMove = !touchless
    },
    'swiper.activeIndex'(index, oldIndex) {
      this.handleSlideChange(index, oldIndex)
    }
  },
  methods: {
    onInit() {
      this.$nextTick(() => {
        this.swiper = this.$refs.swiper.swiper
      })
    },
    async handleSlideChange(index, oldIndex) {
      clearTimeout(this.slideTimeout)
      while (!this.$refs[`swiper-slide-${index}`]) {
        await new Promise(resolve => setTimeout(resolve, 100))
      }
      const newSlideRef = this.$refs[`swiper-slide-${index}`][0]
      const isVideo = Boolean(newSlideRef.play)
      if (isVideo) {
        this.$nextTick(() => {
          newSlideRef.play()
        })
      } else {
        this.slideTimeout = setTimeout(this.next, this.intervalInSeconds * 1000)
      }
      if (oldIndex === undefined) {
        return
      }
      const oldSlideRef = this.$refs[`swiper-slide-${oldIndex}`][0]
      const oldIsVideo = Boolean(oldSlideRef.play)
      if (oldIsVideo) {
        this.$nextTick(() => {
          oldSlideRef.stop()
        })
      }
    },
    next() {
      if (this.swiper.activeIndex === (this.items.length - 1)) {
        this.swiper.slideTo(0)
        return
      }
      this.swiper.slideNext()
    }
  }
}
</script>

<style lang="sass">
  .m-slideshow
    .swiper-container, .swiper-slide
      height: 100%
    .swiper-slide
      display: flex
      align-items: center
</style>
